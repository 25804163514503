import React, { useEffect, useRef, useState } from 'react';

// Refined color palette for a golden, connection-focused experience
const colors = {
  deep: '#0D0A0F',      // Slightly darker background to make gold pop more
  accent1: '#FCCB06',   // Brighter yellow-gold
  accent2: '#E5A83B',   // Warmer golden amber
  highlight: '#FFEBA3', // Brighter gold highlight
  text: '#FFFCF0',      // Warmer white text
  textMuted: '#F5EBD0',  // Cream muted text with golden tint
  shadow: 'rgba(10, 8, 18, 0.7)' // Shadow color for depth
};

// Organize content by category
const categories = {
  "Research & AI": [
    {
      title: "Clinical Linguistics & Speech Analysis",
      description: "Developing machine learning models for analyzing speech patterns in neurodegenerative disorders.",
      url: "#research"
    },
    {
      title: "Swedish Chatbot Arena",
      description: "A platform comparing different LLMs in Swedish language processing.",
      url: "https://chatbotarena.se"
    },
    {
      title: "Swedish Medical Benchmark",
      description: "Evaluating language model performance for healthcare applications in Swedish.",
      url: "https://github.com/BirgerMoell/swedish-medical-benchmark/"
    }
  ],
  "Technical Projects": [
    {
      title: "Portable FNIRS",
      description: "Development of wearable functional near-infrared spectroscopy devices using specialized lasers to non-invasively monitor brain activity and detect cognitive states.",
      url: "https://github.com/BirgerMoell/FNIRS"
    },
    {
      title: "Everything AI Web",
      description: "A sophisticated web platform providing access to state-of-the-art AI models for creators and developers.",
      url: "https://everything-web-one.vercel.app/auth/login"
    },
    {
      title: "Everything AI Discord",
      description: "A powerful Discord bot with access to advanced AI features including document creation, website building, speech, music, images, and video generation.",
      url: "https://everything-ai.ai/"
    },
    {
      title: "Journal",
      description: "An innovative journaling application enhanced by large language models to facilitate reflection and personal growth.",
      url: "https://github.com/BirgerMoell/journal"
    }
  ],
  "Healthcare Innovation": [
    {
      title: "SMART Studying",
      description: "An online cognitive behavioral therapy program helping students with ADHD improve organization, attention, and study abilities through smartphone applications and interactive tools.",
      url: "https://www.slideshare.net/slideshow/smart-studying/35122527#1"
    },
    {
      title: "AI-Powered Therapy",
      description: "Exploring AI applications to improve mental health accessibility and outcomes.",
      url: "https://birgermoell.github.io/friend/"
    },
    {
      title: "Voice Generation for Clinical Use",
      description: "Creating synthetic voice solutions for patients with speech impairments.",
      url: "#healthcare"
    },
    {
      title: "Wellscribe",
      description: "A startup promoting health through reflective journaling and AI-assisted self-care.",
      url: "#healthcare"
    }
  ],
  "Academic Work": [
    {
      title: "Publications",
      description: "Research at the intersection of AI, psychology, and cognitive science.",
      url: "https://scholar.google.com/citations?user=4lPZnEAAAAAJ"
    },
    {
      title: "Speech & Language Processing",
      description: "Multi-modal classifiers for clinical applications and assessments.",
      url: "https://scholar.google.com/citations?user=4lPZnEAAAAAJ"
    },
    {
      title: "Synthetic Speech Datasets",
      description: "Creating specialized datasets for medical transcription and analysis.",
      url: "#academic"
    }
  ],
  "Creative Projects": [
    {
      title: "Abstract Art",
      description: "Traditional paintings exploring consciousness and perception.",
      url: "#art"
    },
    {
      title: "AI-Generated Art",
      description: "Experimental works at the intersection of human creativity and machine learning.",
      url: "#art"
    },
    {
      title: "Swedish GPT",
      description: "A GPT language model trained on Swedish text, including Swedish Wikipedia and the Oscar dataset, one of the first Swedish transformer language models.",
      url: "https://huggingface.co/birgermoell/swedish-gpt"
    },
    {
      title: "Eir",
      description: "A medical domain model for Swedish healthcare applications, merging Swedish Mistral with BioMistral for improved clinical text understanding.",
      url: "https://huggingface.co/birgermoell/eir"
    },
    {
      title: "Swedish Wav2vec2",
      description: "Speech recognition model fine-tuned on Common Voice for Swedish language, enabling accurate transcription of Swedish speech.",
      url: "https://huggingface.co/birgermoell/wav2vec2-swedish-common-voice"
    },
    {
      title: "Rapid Cycling",
      description: "A merged model leveraging multiple foundation models, delivering strong performance for Swedish natural language processing tasks.",
      url: "https://huggingface.co/birgermoell/Rapid-Cycling"
    },
    {
      title: "Synthetic Dysarthria Dataset",
      description: "Speech dataset generated to simulate dysarthric speech patterns in individuals with ALS and CP, addressing data scarcity in speech pathology.",
      url: "https://huggingface.co/datasets/birgermoell/synthetic_dysathria"
    },
    {
      title: "Cookie Theft Reasoning",
      description: "A specialized dataset using the classic cookie theft description task, enhanced with reasoning annotations from DeepSeek R1.",
      url: "https://huggingface.co/datasets/birgermoell/cookie-theft-reasoning/tree/main"
    },
    {
      title: "Synthetic Compassion Dataset",
      description: "Audio dataset of synthetic compassionate responses, designed to model empathetic speech patterns for healthcare applications.",
      url: "https://huggingface.co/datasets/birgermoell/synthetic_compassion_wav"
    }
  ],
  "Knowledge Sharing": [
    {
      title: "Training LLM Models",
      description: "Technical workshops on the creation and fine-tuning of large language models for researchers and developers.",
      url: "https://docs.google.com/presentation/d/1HzsI0c4VJvKHig-pq8rPL6KAGznYIF5To51Aa9aIy5Y/edit?usp=sharing"
    },
    {
      title: "Generative Art for Artists",
      description: "Five-year teaching engagement at Konstfack, introducing art students to AI and generative techniques.",
      url: "https://docs.google.com/presentation/d/1DcR8EIf3M2_WxNHWEGKTOpfa91hwGs_ZofrRZjLYCJE/edit?usp=sharing"
    },
    {
      title: "AI Fundamentals",
      description: "Accessible introductions to artificial intelligence concepts for broader audiences and organizations.",
      url: "https://docs.google.com/presentation/d/1l6DlZk5i7PcEH0Pj1vl-YM7VaZPgwrvUFA51giPn0wA/edit?usp=sharing"
    },
    {
      title: "AI Strategy Consulting",
      description: "Helping organizations navigate cutting-edge AI developments and implement effective AI strategies.",
      url: "#consulting"
    }
  ]
};

// Updated list with removed items
const personalFacts = [
  "Licensed clinical psychologist with expertise in cognitive behavioral therapy",
  "Lives in northwestern Uppland, Sweden with family",
  "Father to a two-year-old son and a newborn baby",
  "Caretaker of two cats named Pettson and Findus",
  "Keeps heritage breed chickens and connects with nature"
];

function App() {
  const sectionRefs = useRef({});
  const [activeSection, setActiveSection] = useState('home');
  const [menuOpen, setMenuOpen] = useState(false);
  const [loaded, setLoaded] = useState(false);

  // Define sections for the menu
  const sections = [
    { id: 'home', label: 'Home' },
    { id: 'research', label: 'Research' },
    { id: 'technical', label: 'Technical' },
    { id: 'healthcare', label: 'Healthcare' },
    { id: 'academic', label: 'Academic' },
    { id: 'creative', label: 'Creative' },
    { id: 'education', label: 'Education' },
    { id: 'personal', label: 'Personal' },
    { id: 'contact', label: 'Contact' }
  ];

  // Add initial loading animation
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoaded(true);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    // Check if device is mobile using window width
    const isMobile = window.innerWidth <= 768;

    const observerOptions = {
      root: null,
      // Apply different options based on device type
      rootMargin: isMobile ? '-20% 0px' : '0px',
      threshold: isMobile ? 0.2 : 0.1,
    };

    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('opacity-100', 'translate-y-0');
          entry.target.classList.remove('opacity-0', 'translate-y-10');
          
          // Only unobserve on mobile devices
          if (isMobile) {
            const id = entry.target.id;
            if (id && id !== activeSection) {
              setActiveSection(id);
              observer.unobserve(entry.target);
            }
          }
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    // Different observation logic for mobile and desktop
    Object.values(sectionRefs.current).forEach((ref) => {
      if (ref) {
        if (isMobile && !ref.classList.contains('opacity-100')) {
          // Mobile-specific behavior
          ref.classList.add('opacity-0', 'translate-y-10', 'transition', 'duration-1000', 'ease-out');
          observer.observe(ref);
        } else if (!isMobile) {
          // Desktop behavior remains unchanged
          ref.classList.add('opacity-0', 'translate-y-10', 'transition', 'duration-1000');
          observer.observe(ref);
        }
      }
    });

    // Add resize listener to update mobile detection
    const handleResize = () => {
      const newIsMobile = window.innerWidth <= 768;
      if (newIsMobile !== isMobile) {
        // Reconnect observer with new options if device type changes
        observer.disconnect();
        Object.values(sectionRefs.current).forEach((ref) => {
          if (ref) observer.observe(ref);
        });
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      if (observer) {
        observer.disconnect();
      }
      window.removeEventListener('resize', handleResize);
    };
  }, [activeSection]);

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      window.scrollTo({
        top: section.offsetTop - 100,
        behavior: 'smooth'
      });
    }
    setMenuOpen(false);
  };

  // Refined styles for a golden, connection-focused experience
  const styles = {
    container: {
      opacity: loaded ? 1 : 0,
      transition: 'opacity 1.8s ease-in-out'
    },
    gradientBg: {
      background: `linear-gradient(135deg, ${colors.deep}, ${colors.accent2}30, ${colors.accent1}25)`,
      backgroundSize: '400% 400%',
      animation: 'gradient 50s ease infinite'
    },
    starField: {
      position: 'absolute',
      inset: 0,
      background: `radial-gradient(circle at 20% 30%, ${colors.textMuted}10 1px, transparent 1px), 
                   radial-gradient(circle at 80% 20%, ${colors.accent1}15 1px, transparent 1px), 
                   radial-gradient(circle at 40% 80%, ${colors.highlight}15 1px, transparent 1px)`,
      backgroundSize: '120px 120px, 150px 150px, 90px 90px',
      opacity: 0.8
    },
    aurora: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      height: '80vh',
      background: `linear-gradient(to bottom, ${colors.accent1}30, transparent)`,
      clipPath: 'polygon(0 0, 100% 0, 100% 95%, 0% 100%)',
      opacity: 0.6,
      filter: 'blur(80px)'
    },
    pulseBg1: {
      background: `${colors.accent1}30`,
      animation: 'pulse 25s ease-in-out infinite',
      filter: 'blur(120px)',
      opacity: 0.35
    },
    pulseBg2: {
      background: `${colors.accent2}30`,
      animation: 'pulse 30s ease-in-out infinite',
      animationDelay: '8s',
      filter: 'blur(140px)',
      opacity: 0.3
    },
    pulseBg3: {
      background: `${colors.highlight}25`,
      animation: 'pulse 35s ease-in-out infinite',
      animationDelay: '15s',
      filter: 'blur(150px)',
      opacity: 0.25
    },
    navBg: {
      backdropFilter: 'blur(18px)',
      background: `${colors.deep}40`,
      borderBottom: `1px solid ${colors.accent1}30`
    },
    menuBg: {
      backdropFilter: 'blur(18px)',
      background: `${colors.deep}70`
    },
    cardBg: {
      backdropFilter: 'blur(12px)',
      background: 'rgba(13, 10, 15, 0.4)',
      border: `1px solid ${colors.accent1}25`,
      boxShadow: `0 15px 35px -5px ${colors.shadow}, 0 0 0 1px ${colors.accent1}15`
    },
    highlight: {
      color: colors.highlight,
      textShadow: `0 0 25px ${colors.highlight}70`
    },
    accentButton: {
      borderColor: colors.accent1,
      color: colors.text,
      background: `linear-gradient(to right, ${colors.accent1}40, ${colors.accent2}30)`,
      boxShadow: `0 0 25px ${colors.accent1}35, inset 0 0 20px ${colors.accent1}25`
    },
    accentButtonHover: {
      background: `linear-gradient(to right, ${colors.accent1}55, ${colors.accent2}40)`,
      boxShadow: `0 0 30px ${colors.accent1}45, inset 0 0 25px ${colors.accent1}30`
    },
    highlightButton: {
      borderColor: colors.highlight,
      color: colors.highlight,
      background: `linear-gradient(to right, ${colors.highlight}25, ${colors.accent1}15)`,
      boxShadow: `0 0 25px ${colors.highlight}25, inset 0 0 20px ${colors.highlight}15`
    },
    highlightButtonHover: {
      background: `linear-gradient(to right, ${colors.highlight}40, ${colors.accent1}25)`,
      boxShadow: `0 0 30px ${colors.highlight}35, inset 0 0 25px ${colors.highlight}25`
    },
    linkGlow: {
      background: `${colors.accent1}25`,
      boxShadow: `0 0 20px ${colors.accent1}35`
    },
    linkGlowHover: {
      background: `${colors.accent1}35`,
      boxShadow: `0 0 25px ${colors.accent1}45`
    }
  };

  // CSS for animations to be injected
  const animationStyles = `
    @keyframes gradient {
      0% { background-position: 0% 50% }
      50% { background-position: 100% 50% }
      100% { background-position: 0% 50% }
    }
    @keyframes pulse {
      0%, 100% { transform: scale(1); opacity: 0.2; }
      50% { transform: scale(1.08); opacity: 0.4; }
    }
    @keyframes float {
      0%, 100% { transform: translateY(0px); }
      50% { transform: translateY(-12px); }
    }
    @keyframes fadeIn {
      from { opacity: 0; transform: translateY(20px); }
      to { opacity: 1; transform: translateY(0); }
    }
    @keyframes gentlePulse {
      0%, 100% { opacity: 0.7; }
      50% { opacity: 1; }
    }
    @keyframes shimmer {
      0% { background-position: -100% 0; }
      100% { background-position: 200% 0; }
    }
    ::selection {
      background: ${colors.accent1}40;
      color: ${colors.highlight};
    }
    body {
      background-color: ${colors.deep};
    }
  `;

  return (
    <div className="min-h-screen text-white overflow-hidden" style={{ ...styles.container, background: colors.deep }}>
      {/* Inject animations */}
      <style>{animationStyles}</style>
      
      {/* Enhanced animated background */}
      <div className="fixed inset-0" style={styles.gradientBg}>
        {/* Star field */}
        <div style={styles.starField}></div>
        
        {/* Aurora effect */}
        <div style={styles.aurora}></div>
        
        {/* Abstract animated orbs */}
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute top-[28%] left-[38%] w-[65vw] h-[65vw] max-w-[1000px] max-h-[1000px] rounded-full" style={styles.pulseBg1}></div>
          <div className="absolute top-[60%] left-[65%] w-[45vw] h-[45vw] max-w-[850px] max-h-[850px] rounded-full" style={styles.pulseBg2}></div>
          <div className="absolute top-[72%] left-[8%] w-[55vw] h-[55vw] max-w-[950px] max-h-[950px] rounded-full" style={styles.pulseBg3}></div>
        </div>
      </div>

      {/* Fixed navigation */}
      <header className="fixed top-0 left-0 w-full z-50 transition-all duration-500">
        <nav className="px-8 sm:px-16 py-6" style={styles.navBg}>
          <div className="max-w-screen-2xl mx-auto flex justify-between items-center">
            <div 
              onClick={() => scrollToSection('home')} 
              className="cursor-pointer text-white text-xl font-extralight tracking-widest hover:text-amber-200 transition-colors duration-500"
            >
              BIRGER MOËLL
            </div>
            
            {/* Mobile menu button */}
            <button 
              className="md:hidden text-white"
              onClick={() => setMenuOpen(!menuOpen)}
            >
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.2} d={menuOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"} />
              </svg>
            </button>
            
            {/* Desktop menu */}
            <div className="hidden md:flex space-x-12">
              {sections.slice(1).map((section) => (
                <button
                  key={section.id}
                  onClick={() => scrollToSection(section.id)}
                  className={`text-xs uppercase tracking-[0.25em] font-extralight ${
                    activeSection === section.id ? 'text-amber-200' : 'text-white/60'
                  } hover:text-amber-200 transition-colors duration-500`}
                >
                  {section.label}
                </button>
              ))}
            </div>
          </div>
        </nav>
        
        {/* Mobile menu */}
        <div 
          className={`md:hidden absolute w-full transition-all duration-500 ${
            menuOpen ? 'max-h-screen opacity-100' : 'max-h-0 opacity-0 overflow-hidden'
          }`}
          style={styles.menuBg}
        >
          <div className="px-8 py-6">
            {sections.slice(1).map((section) => (
              <button
                key={section.id}
                onClick={() => scrollToSection(section.id)}
                className={`block w-full text-left py-5 border-b border-white/5 ${
                  activeSection === section.id ? 'text-amber-200' : 'text-white/60'
                } hover:text-amber-200 transition-colors duration-500 text-sm tracking-wider font-extralight`}
              >
                {section.label}
              </button>
            ))}
          </div>
        </div>
      </header>

      <main className="relative z-10 pt-32">
        {/* Hero Section - Elegant, Text-focused */}
        <section 
          id="home"
          ref={(el) => (sectionRefs.current.home = el)}
          className="min-h-[90vh] flex flex-col justify-center px-8 lg:px-0"
        >
          <div className="max-w-screen-lg mx-auto w-full text-center">
            <h1 className="text-7xl md:text-8xl lg:text-9xl font-thin tracking-tight mb-16 text-white leading-tight">
              Birger <span style={styles.highlight} className="font-extralight">Moëll</span>
            </h1>
            
            <p className="text-xl md:text-2xl text-white/70 font-extralight max-w-3xl mx-auto leading-relaxed mt-8 mb-24 tracking-wide">
              A researcher, psychologist, and AI developer who loves people and strives to create meaningful connections through technology and understanding.
            </p>
            
            <div className="flex flex-wrap justify-center gap-10 mt-16">
              <button 
                onClick={() => scrollToSection('research')}
                className="px-12 py-4 border border-opacity-40 rounded-full transition-all duration-500 transform hover:scale-105"
                style={styles.accentButton}
                onMouseOver={(e) => {
                  e.currentTarget.style.background = styles.accentButtonHover.background;
                  e.currentTarget.style.boxShadow = styles.accentButtonHover.boxShadow;
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.background = styles.accentButton.background;
                  e.currentTarget.style.boxShadow = styles.accentButton.boxShadow;
                }}
              >
                <span className="font-extralight tracking-widest text-sm">EXPLORE MY WORK</span>
              </button>
              <button 
                onClick={() => scrollToSection('contact')}
                className="px-12 py-4 border border-opacity-40 rounded-full transition-all duration-500 transform hover:scale-105"
                style={styles.highlightButton}
                onMouseOver={(e) => {
                  e.currentTarget.style.background = styles.highlightButtonHover.background;
                  e.currentTarget.style.boxShadow = styles.highlightButtonHover.boxShadow;
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.background = styles.highlightButton.background;
                  e.currentTarget.style.boxShadow = styles.highlightButton.boxShadow;
                }}
              >
                <span className="font-extralight tracking-widest text-sm">CONNECT WITH ME</span>
              </button>
            </div>
          </div>

          <div className="absolute bottom-12 left-0 right-0 flex justify-center">
            <div className="animate-bounce cursor-pointer" onClick={() => scrollToSection('research')}>
              <svg className="w-8 h-8 text-amber-300/30" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M19 14l-7 7m0 0l-7-7m7 7V3"></path>
              </svg>
            </div>
          </div>
        </section>

        {/* Research Section */}
        <section 
          id="research"
          ref={(el) => (sectionRefs.current.research = el)}
          className="py-40 px-8 lg:px-0"
        >
          <div className="max-w-screen-lg mx-auto">
            <h2 className="text-4xl md:text-5xl font-thin mb-6 text-center tracking-wide">
              Research <span style={styles.highlight} className="font-extralight">&</span> AI
            </h2>
            
            <p className="text-center text-white/60 font-extralight max-w-2xl mx-auto mb-40 text-lg tracking-wide">
              Exploring the intersections of artificial intelligence, language, and human cognition to foster deeper connections and understanding.
            </p>
            
            <div className="grid md:grid-cols-2 gap-16 md:gap-24">
              {categories["Research & AI"].map((project, index) => (
                <a 
                  key={index}
                  href={project.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="group p-10 rounded-2xl transition-all duration-700 transform hover:-translate-y-2 hover:shadow-xl"
                  style={styles.cardBg}
                >
                  <h3 className="text-2xl md:text-3xl font-thin mb-6 text-white group-hover:text-amber-200 transition-colors duration-500">
                    {project.title}
                  </h3>
                  <p className="text-white/50 text-lg leading-relaxed font-extralight">
                    {project.description}
                  </p>
                </a>
              ))}
            </div>
          </div>
        </section>

        {/* Technical Projects Section */}
        <section 
          id="technical"
          ref={(el) => (sectionRefs.current.technical = el)}
          className="py-40 px-8 lg:px-0"
        >
          <div className="max-w-screen-lg mx-auto">
            <h2 className="text-4xl md:text-5xl font-thin mb-6 text-center tracking-wide">
              Technical <span style={styles.highlight} className="font-extralight">Projects</span>
            </h2>
            
            <p className="text-center text-white/60 font-extralight max-w-2xl mx-auto mb-40 text-lg tracking-wide">
              Creating innovative platforms and tools that harness the power of AI to connect people with cutting-edge technology in meaningful ways.
            </p>
            
            <div className="grid md:grid-cols-2 gap-16 md:gap-24">
              {categories["Technical Projects"].map((project, index) => (
                <a 
                  key={index}
                  href={project.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="group p-10 rounded-2xl transition-all duration-700 transform hover:-translate-y-2 hover:shadow-xl"
                  style={styles.cardBg}
                >
                  <h3 className="text-2xl md:text-3xl font-thin mb-6 text-white group-hover:text-amber-200 transition-colors duration-500">
                    {project.title}
                  </h3>
                  <p className="text-white/50 text-lg leading-relaxed font-extralight">
                    {project.description}
                  </p>
                </a>
              ))}
            </div>
          </div>
        </section>

        {/* Healthcare Innovation Section */}
        <section 
          id="healthcare"
          ref={(el) => (sectionRefs.current.healthcare = el)}
          className="py-40 px-8 lg:px-0"
        >
          <div className="max-w-screen-lg mx-auto">
            <h2 className="text-4xl md:text-5xl font-thin mb-6 text-center tracking-wide">
              Healthcare <span style={styles.highlight} className="font-extralight">Innovation</span>
            </h2>
            
            <p className="text-center text-white/60 font-extralight max-w-2xl mx-auto mb-40 text-lg tracking-wide">
              Creating technology that enhances human wellbeing and facilitates healing through compassionate, intelligent systems.
            </p>
            
            <div className="grid md:grid-cols-2 gap-16 md:gap-24">
              {categories["Healthcare Innovation"].map((project, index) => (
                <a 
                  key={index}
                  href={project.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="group p-10 rounded-2xl transition-all duration-700 transform hover:-translate-y-2 hover:shadow-xl"
                  style={styles.cardBg}
                >
                  <h3 className="text-2xl md:text-3xl font-thin mb-6 text-white group-hover:text-amber-200 transition-colors duration-500">
                    {project.title}
                  </h3>
                  <p className="text-white/50 text-lg leading-relaxed font-extralight">
                    {project.description}
                  </p>
                </a>
              ))}
            </div>
          </div>
        </section>

        {/* Academic Work Section */}
        <section 
          id="academic"
          ref={(el) => (sectionRefs.current.academic = el)}
          className="py-40 px-8 lg:px-0"
        >
          <div className="max-w-screen-lg mx-auto">
            <h2 className="text-4xl md:text-5xl font-thin mb-6 text-center tracking-wide">
              Academic <span style={styles.highlight} className="font-extralight">Work</span>
            </h2>
            
            <p className="text-center text-white/60 font-extralight max-w-2xl mx-auto mb-40 text-lg tracking-wide">
              Bridging research and application to nurture knowledge that serves humanity and deepens our understanding of ourselves.
            </p>
            
            <div className="grid md:grid-cols-2 gap-16 md:gap-24">
              {categories["Academic Work"].map((project, index) => (
                <a 
                  key={index}
                  href={project.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="group p-10 rounded-2xl transition-all duration-700 transform hover:-translate-y-2 hover:shadow-xl"
                  style={styles.cardBg}
                >
                  <h3 className="text-2xl md:text-3xl font-thin mb-6 text-white group-hover:text-amber-200 transition-colors duration-500">
                    {project.title}
                  </h3>
                  <p className="text-white/50 text-lg leading-relaxed font-extralight">
                    {project.description}
                  </p>
                </a>
              ))}
            </div>

            {/* Publication List */}
            <div className="mt-40">
              <h3 className="text-3xl md:text-4xl font-thin mb-12 text-center">
                Selected <span style={styles.highlight} className="font-extralight">Publications</span>
              </h3>
              
              <div className="p-12 rounded-2xl" style={styles.cardBg}>
                {/* 2025 Publications */}
                <div className="mb-16">
                  <div className="flex items-center mb-6">
                    <h4 className="text-xl font-thin text-white tracking-wider">2025</h4>
                    <div className="ml-4 flex-grow h-[1px]" style={{background: `linear-gradient(to right, ${colors.accent1}50, transparent)`}}></div>
                  </div>
                  
                  <div className="space-y-8">
                    <div className="group">
                      <h5 className="text-lg md:text-xl font-thin mb-2 text-white group-hover:text-amber-200 transition-colors duration-500">
                        The order in speech disorder: a scoping review of state of the art machine learning methods for clinical speech classification
                      </h5>
                      <p className="text-white/50 text-base font-extralight">
                        B Moell, FS Aronsson, P Östberg, J Beskow | arXiv preprint
                      </p>
                    </div>
                    
                    <div className="group">
                      <h5 className="text-lg md:text-xl font-thin mb-2 text-white group-hover:text-amber-200 transition-colors duration-500">
                        Voice Cloning for Dysarthric Speech Synthesis: Addressing Data Scarcity in Speech-Language Pathology
                      </h5>
                      <p className="text-white/50 text-base font-extralight">
                        B Moell, FS Aronsson | arXiv preprint
                      </p>
                    </div>
                    
                    <div className="group">
                      <h5 className="text-lg md:text-xl font-thin mb-2 text-white group-hover:text-amber-200 transition-colors duration-500">
                        Large Language Models and Mathematical Reasoning Failures
                      </h5>
                      <p className="text-white/50 text-base font-extralight">
                        J Boye, B Moell | arXiv preprint
                      </p>
                    </div>
                    
                    <div className="group">
                      <h5 className="text-lg md:text-xl font-thin mb-2 text-white group-hover:text-amber-200 transition-colors duration-500">
                        Language Complexity Measurement as a Noisy Zero-Shot Proxy for Evaluating LLM Performance
                      </h5>
                      <p className="text-white/50 text-base font-extralight">
                        B Moell, J Boye | arXiv preprint
                      </p>
                    </div>
                  </div>
                </div>
                
                {/* 2024 Publications */}
                <div className="mb-16">
                  <div className="flex items-center mb-6">
                    <h4 className="text-xl font-thin text-white tracking-wider">2024</h4>
                    <div className="ml-4 flex-grow h-[1px]" style={{background: `linear-gradient(to right, ${colors.accent1}50, transparent)`}}></div>
                  </div>
                  
                  <div className="space-y-8">
                    <div className="group">
                      <h5 className="text-lg md:text-xl font-thin mb-2 text-white group-hover:text-amber-200 transition-colors duration-500">
                        You don't understand me!: Comparing ASR results for L1 and L2 speakers of Swedish
                      </h5>
                      <p className="text-white/50 text-base font-extralight">
                        R Cumbal, B Moell, J Lopes, O Engwall | arXiv preprint
                      </p>
                    </div>
                    
                    <div className="group">
                      <h5 className="text-lg md:text-xl font-thin mb-2 text-white group-hover:text-amber-200 transition-colors duration-500">
                        Fake it to make it: Using synthetic data to remedy the data shortage in joint multimodal speech-and-gesture synthesis
                      </h5>
                      <p className="text-white/50 text-base font-extralight">
                        S Mehta, A Deichler, J O'regan, B Moëll, J Beskow, GE Henter | Proceedings of the IEEE/CVF Conference on Computer Vision and Pattern Recognition
                      </p>
                    </div>
                    
                    <div className="group">
                      <h5 className="text-lg md:text-xl font-thin mb-2 text-white group-hover:text-amber-200 transition-colors duration-500">
                        Comparing the efficacy of GPT-4 and Chat-GPT in mental health care: A blind assessment of large language models for psychological support
                      </h5>
                      <p className="text-white/50 text-base font-extralight">
                        B Moell | arXiv preprint
                      </p>
                    </div>
                    
                    <div className="group">
                      <h5 className="text-lg md:text-xl font-thin mb-2 text-white group-hover:text-amber-200 transition-colors duration-500">
                        Evaluating Large Language Models with Human Feedback: Establishing a Swedish Benchmark
                      </h5>
                      <p className="text-white/50 text-base font-extralight">
                        B Moell | arXiv preprint
                      </p>
                    </div>
                    
                    <div className="group">
                      <h5 className="text-lg md:text-xl font-thin mb-2 text-white group-hover:text-amber-200 transition-colors duration-500">
                        Scoping Review-NLP and Gen-AI in Primary Care
                      </h5>
                      <p className="text-white/50 text-base font-extralight">
                        D Sundemo, A Entezarjou, B Moell, FS Aronsson, R Arvidsson | OSF
                      </p>
                    </div>
                    
                    <div className="group">
                      <h5 className="text-lg md:text-xl font-thin mb-2 text-white group-hover:text-amber-200 transition-colors duration-500">
                        Empathy and Algorithms: Comparing Written Psychological Advice by AI and Experts
                      </h5>
                      <p className="text-white/50 text-base font-extralight">
                        E Zapel, E Lindsäter, B Moell, M Lekander, LF Föyen | OSF
                      </p>
                    </div>
                  </div>
                </div>
                
                {/* 2022 Publications */}
                <div className="mb-16">
                  <div className="flex items-center mb-6">
                    <h4 className="text-xl font-thin text-white tracking-wider">2022</h4>
                    <div className="ml-4 flex-grow h-[1px]" style={{background: `linear-gradient(to right, ${colors.accent1}50, transparent)`}}></div>
                  </div>
                  
                  <div className="space-y-8">
                    <div className="group">
                      <h5 className="text-lg md:text-xl font-thin mb-2 text-white group-hover:text-amber-200 transition-colors duration-500">
                        Speech data augmentation for improving phoneme transcriptions of aphasic speech using wav2vec 2.0 for the psst challenge
                      </h5>
                      <p className="text-white/50 text-base font-extralight">
                        B Moëll, J O'Regan, S Mehta, A Kirkland, H Lameris, J Gustafsson | 4th RaPID Workshop
                      </p>
                    </div>
                    
                    <div className="group">
                      <h5 className="text-lg md:text-xl font-thin mb-2 text-white group-hover:text-amber-200 transition-colors duration-500">
                        Spontaneous Neural HMM TTS with Prosodic Feature Modification
                      </h5>
                      <p className="text-white/50 text-base font-extralight">
                        H Lameris, S Mehta, GE Henter, A Kirkland, B Moëll, J O'Regan | Fonetik 2022
                      </p>
                    </div>
                  </div>
                </div>
                
                {/* 2021 Publications */}
                <div className="mb-16">
                  <div className="flex items-center mb-6">
                    <h4 className="text-xl font-thin text-white tracking-wider">2021</h4>
                    <div className="ml-4 flex-grow h-[1px]" style={{background: `linear-gradient(to right, ${colors.accent1}50, transparent)`}}></div>
                  </div>
                  
                  <div className="space-y-8">
                    <div className="group">
                      <h5 className="text-lg md:text-xl font-thin mb-2 text-white group-hover:text-amber-200 transition-colors duration-500">
                        Multimodal capture of patient behaviour for improved detection of early dementia: clinical feasibility and preliminary results
                      </h5>
                      <p className="text-white/50 text-base font-extralight">
                        P Jonell, B Moëll, K Håkansson, GE Henter, T Kucherenko, O Mikheeva | Frontiers in Computer Science
                      </p>
                    </div>
                    
                    <div className="group">
                      <h5 className="text-lg md:text-xl font-thin mb-2 text-white group-hover:text-amber-200 transition-colors duration-500">
                        A framework for integrating gesture generation models into interactive conversational agents
                      </h5>
                      <p className="text-white/50 text-base font-extralight">
                        R Nagy, T Kucherenko, B Moell, A Pereira, H Kjellström, U Bernardet | arXiv preprint
                      </p>
                    </div>
                  </div>
                </div>
                
                {/* 2017 Publication */}
                <div className="mb-16">
                  <div className="flex items-center mb-6">
                    <h4 className="text-xl font-thin text-white tracking-wider">2017</h4>
                    <div className="ml-4 flex-grow h-[1px]" style={{background: `linear-gradient(to right, ${colors.accent1}50, transparent)`}}></div>
                  </div>
                  
                  <div className="space-y-8">
                    <div className="group">
                      <h5 className="text-lg md:text-xl font-thin mb-2 text-white group-hover:text-amber-200 transition-colors duration-500">
                        Towards Bidirectional Brain-computer Interfaces that Use fNIRS and tDCS
                      </h5>
                      <p className="text-white/50 text-base font-extralight">
                        SW Hincks, M DeBellis, EY Lee, R ten Brink, B Moëll, RJK Jacob | PhyCS
                      </p>
                    </div>
                  </div>
                </div>
                
                {/* 2015 & 2013 Publications */}
                <div>
                  <div className="flex items-center mb-6">
                    <h4 className="text-xl font-thin text-white tracking-wider">2015 & 2013</h4>
                    <div className="ml-4 flex-grow h-[1px]" style={{background: `linear-gradient(to right, ${colors.accent1}50, transparent)`}}></div>
                  </div>
                  
                  <div className="space-y-8">
                    <div className="group">
                      <h5 className="text-lg md:text-xl font-thin mb-2 text-white group-hover:text-amber-200 transition-colors duration-500">
                        Living SMART—A randomized controlled trial of a guided online course teaching adults with ADHD or sub-clinical ADHD to use smartphones to structure their everyday life
                      </h5>
                      <p className="text-white/50 text-base font-extralight">
                        B Moëll, L Kollberg, B Nasri, N Lindefors, V Kaldo | Internet Interventions
                      </p>
                    </div>
                    
                    <div className="group">
                      <h5 className="text-lg md:text-xl font-thin mb-2 text-white group-hover:text-amber-200 transition-colors duration-500">
                        Living SMART: an Internet course for adults with ADHD
                      </h5>
                      <p className="text-white/50 text-base font-extralight">
                        B Moëll | 2013
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="flex justify-center mt-16">
                <a 
                  href="https://scholar.google.com/citations?user=4lPZnEAAAAAJ"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="group transition-all duration-500 hover:scale-105"
                >
                  <span className="relative inline-block px-12 py-4 border border-opacity-40 rounded-full"
                    style={styles.accentButton}
                    onMouseOver={(e) => {
                      e.currentTarget.style.background = styles.accentButtonHover.background;
                      e.currentTarget.style.boxShadow = styles.accentButtonHover.boxShadow;
                    }}
                    onMouseOut={(e) => {
                      e.currentTarget.style.background = styles.accentButton.background;
                      e.currentTarget.style.boxShadow = styles.accentButton.boxShadow;
                    }}>
                    <span className="font-extralight tracking-widest text-sm">VIEW ALL PUBLICATIONS</span>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </section>

        {/* Creative Projects Section */}
        <section 
          id="creative"
          ref={(el) => (sectionRefs.current.creative = el)}
          className="py-40 px-8 lg:px-0"
        >
          <div className="max-w-screen-lg mx-auto">
            <h2 className="text-4xl md:text-5xl font-thin mb-6 text-center tracking-wide">
              Creative <span style={styles.highlight} className="font-extralight">Projects</span>
            </h2>
            
            <p className="text-center text-white/60 font-extralight max-w-2xl mx-auto mb-40 text-lg tracking-wide">
              Expressing ideas through art and technology that inspire connection, wonder, and contemplation of our shared experience.
            </p>
            
            {/* Art Projects */}
            <div className="mb-40">
              <h3 className="text-3xl font-thin mb-16 text-center">
                <span style={styles.highlight} className="font-extralight">Artistic</span> Works
              </h3>
              <div className="grid md:grid-cols-2 gap-16 md:gap-24">
                {categories["Creative Projects"].slice(0, 2).map((project, index) => (
                  <a 
                    key={index}
                    href={project.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="group p-10 rounded-2xl transition-all duration-700 transform hover:-translate-y-2 hover:shadow-xl"
                    style={styles.cardBg}
                  >
                    <h3 className="text-2xl md:text-3xl font-thin mb-6 text-white group-hover:text-amber-200 transition-colors duration-500">
                      {project.title}
                    </h3>
                    <p className="text-white/50 text-lg leading-relaxed font-extralight">
                      {project.description}
                    </p>
                  </a>
                ))}
              </div>
            </div>
            
            {/* Language Models Section */}
            <div className="mb-40">
              <h3 className="text-3xl font-thin mb-16 text-center">
                <span style={styles.highlight} className="font-extralight">Language</span> Models
              </h3>
              <div className="grid md:grid-cols-2 gap-16 md:gap-24">
                {categories["Creative Projects"].slice(2, 6).map((project, index) => (
                  <a 
                    key={index}
                    href={project.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="group p-10 rounded-2xl transition-all duration-700 transform hover:-translate-y-2 hover:shadow-xl"
                    style={styles.cardBg}
                  >
                    <h3 className="text-2xl md:text-3xl font-thin mb-6 text-white group-hover:text-amber-200 transition-colors duration-500">
                      {project.title}
                    </h3>
                    <p className="text-white/50 text-lg leading-relaxed font-extralight">
                      {project.description}
                    </p>
                  </a>
                ))}
              </div>
            </div>
            
            {/* Research Datasets */}
            <div>
              <h3 className="text-3xl font-thin mb-16 text-center">
                <span style={styles.highlight} className="font-extralight">Research</span> Datasets
              </h3>
              <div className="grid md:grid-cols-2 gap-16 md:gap-24">
                {categories["Creative Projects"].slice(6).map((project, index) => (
                  <a 
                    key={index}
                    href={project.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="group p-10 rounded-2xl transition-all duration-700 transform hover:-translate-y-2 hover:shadow-xl"
                    style={styles.cardBg}
                  >
                    <h3 className="text-2xl md:text-3xl font-thin mb-6 text-white group-hover:text-amber-200 transition-colors duration-500">
                      {project.title}
                    </h3>
                    <p className="text-white/50 text-lg leading-relaxed font-extralight">
                      {project.description}
                    </p>
                  </a>
                ))}
              </div>
            </div>
          </div>
        </section>

        {/* Education Section */}
        <section 
          id="education"
          ref={(el) => (sectionRefs.current.education = el)}
          className="py-40 px-8 lg:px-0"
        >
          <div className="max-w-screen-lg mx-auto">
            <h2 className="text-4xl md:text-5xl font-thin mb-6 text-center tracking-wide">
              AI <span style={styles.highlight} className="font-extralight">Education</span>
            </h2>
            
            <p className="text-center text-white/60 font-extralight max-w-2xl mx-auto mb-20 text-lg tracking-wide">
              Sharing knowledge and demystifying artificial intelligence through teaching, workshops, and consulting to empower the next generation of innovators.
            </p>
            
            <div className="grid md:grid-cols-2 gap-16 md:gap-24 mb-32">
              {categories["Knowledge Sharing"].map((project, index) => (
                <a 
                  key={index}
                  href={project.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="group p-10 rounded-2xl transition-all duration-700 transform hover:-translate-y-2 hover:shadow-xl"
                  style={styles.cardBg}
                >
                  <h3 className="text-2xl md:text-3xl font-thin mb-6 text-white group-hover:text-amber-200 transition-colors duration-500">
                    {project.title}
                  </h3>
                  <p className="text-white/50 text-lg leading-relaxed font-extralight">
                    {project.description}
                  </p>
                </a>
              ))}
            </div>
            
            {/* Model Training Section */}
            <div className="p-14 rounded-2xl max-w-5xl mx-auto" style={styles.cardBg}>
              <h3 className="text-3xl font-thin mb-10 text-center">
                <span style={styles.highlight} className="font-extralight">Technical</span> Training Expertise
              </h3>
              
              <p className="text-xl text-white/60 leading-relaxed mb-12 font-extralight tracking-wide">
                I provide specialized workshops and training on advanced AI model development, covering the full spectrum from data preparation to deployment.
              </p>
              
              <div className="grid md:grid-cols-2 gap-10 mb-12">
                <div>
                  <h4 className="text-xl font-thin mb-4 text-white/90">Model Architecture</h4>
                  <ul className="space-y-3">
                    <li className="flex items-start gap-3">
                      <span style={{...styles.highlight, animation: 'gentlePulse 3s infinite ease-in-out'}} className="text-lg mt-1">✧</span>
                      <p className="text-white/50 text-base font-extralight">Transformer architectures & attention mechanisms</p>
                    </li>
                    <li className="flex items-start gap-3">
                      <span style={{...styles.highlight, animation: 'gentlePulse 3s infinite ease-in-out'}} className="text-lg mt-1">✧</span>
                      <p className="text-white/50 text-base font-extralight">Encoder-decoder vs. decoder-only models</p>
                    </li>
                    <li className="flex items-start gap-3">
                      <span style={{...styles.highlight, animation: 'gentlePulse 3s infinite ease-in-out'}} className="text-lg mt-1">✧</span>
                      <p className="text-white/50 text-base font-extralight">Scaling laws and parameter efficiency</p>
                    </li>
                  </ul>
                </div>
                
                <div>
                  <h4 className="text-xl font-thin mb-4 text-white/90">Training Methodologies</h4>
                  <ul className="space-y-3">
                    <li className="flex items-start gap-3">
                      <span style={{...styles.highlight, animation: 'gentlePulse 3s infinite ease-in-out'}} className="text-lg mt-1">✧</span>
                      <p className="text-white/50 text-base font-extralight">Pre-training and fine-tuning approaches</p>
                    </li>
                    <li className="flex items-start gap-3">
                      <span style={{...styles.highlight, animation: 'gentlePulse 3s infinite ease-in-out'}} className="text-lg mt-1">✧</span>
                      <p className="text-white/50 text-base font-extralight">RLHF and instruction tuning techniques</p>
                    </li>
                    <li className="flex items-start gap-3">
                      <span style={{...styles.highlight, animation: 'gentlePulse 3s infinite ease-in-out'}} className="text-lg mt-1">✧</span>
                      <p className="text-white/50 text-base font-extralight">Model merging and knowledge distillation</p>
                    </li>
                  </ul>
                </div>
              </div>
              
              <div className="grid md:grid-cols-2 gap-10">
                <div>
                  <h4 className="text-xl font-thin mb-4 text-white/90">Dataset Engineering</h4>
                  <ul className="space-y-3">
                    <li className="flex items-start gap-3">
                      <span style={{...styles.highlight, animation: 'gentlePulse 3s infinite ease-in-out'}} className="text-lg mt-1">✧</span>
                      <p className="text-white/50 text-base font-extralight">Data curation and quality assessment</p>
                    </li>
                    <li className="flex items-start gap-3">
                      <span style={{...styles.highlight, animation: 'gentlePulse 3s infinite ease-in-out'}} className="text-lg mt-1">✧</span>
                      <p className="text-white/50 text-base font-extralight">Synthetic data generation strategies</p>
                    </li>
                    <li className="flex items-start gap-3">
                      <span style={{...styles.highlight, animation: 'gentlePulse 3s infinite ease-in-out'}} className="text-lg mt-1">✧</span>
                      <p className="text-white/50 text-base font-extralight">Domain-specific data preparation</p>
                    </li>
                  </ul>
                </div>
                
                <div>
                  <h4 className="text-xl font-thin mb-4 text-white/90">Deployment & Integration</h4>
                  <ul className="space-y-3">
                    <li className="flex items-start gap-3">
                      <span style={{...styles.highlight, animation: 'gentlePulse 3s infinite ease-in-out'}} className="text-lg mt-1">✧</span>
                      <p className="text-white/50 text-base font-extralight">Efficient inference for production environments</p>
                    </li>
                    <li className="flex items-start gap-3">
                      <span style={{...styles.highlight, animation: 'gentlePulse 3s infinite ease-in-out'}} className="text-lg mt-1">✧</span>
                      <p className="text-white/50 text-base font-extralight">Quantization and model optimization</p>
                    </li>
                    <li className="flex items-start gap-3">
                      <span style={{...styles.highlight, animation: 'gentlePulse 3s infinite ease-in-out'}} className="text-lg mt-1">✧</span>
                      <p className="text-white/50 text-base font-extralight">API design and system integration</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            
            {/* Teaching Highlights */}
            <div className="mt-32">
              <h3 className="text-3xl font-thin mb-16 text-center">
                <span style={styles.highlight} className="font-extralight">Teaching</span> Highlights
              </h3>
              
              <div className="grid md:grid-cols-3 gap-12">
                <div className="text-center p-8">
                  <div className="text-4xl mb-6 font-thin text-amber-200">5+</div>
                  <p className="text-white/60 font-extralight">Years teaching AI at Konstfack arts university, introducing technical concepts to creative minds</p>
                </div>
                
                <div className="text-center p-8">
                  <div className="text-4xl mb-6 font-thin text-amber-200">100+</div>
                  <p className="text-white/60 font-extralight">Workshops conducted for students, professionals, and researchers across various disciplines</p>
                </div>
                
                <div className="text-center p-8">
                  <div className="text-4xl mb-6 font-thin text-amber-200">20+</div>
                  <p className="text-white/60 font-extralight">Organizations provided with strategic AI consulting and specialized training programs</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Personal Section */}
        <section 
          id="personal"
          ref={(el) => (sectionRefs.current.personal = el)}
          className="py-40 px-8 lg:px-0"
        >
          <div className="max-w-screen-lg mx-auto">
            <h2 className="text-4xl md:text-5xl font-thin mb-6 text-center tracking-wide">
              Personal <span style={styles.highlight} className="font-extralight">Life</span>
            </h2>
            
            <p className="text-center text-white/60 font-extralight max-w-2xl mx-auto mb-40 text-lg tracking-wide">
              Finding joy in the richness of relationships, the wisdom of nature, and the pursuit of a meaningful, connected existence.
            </p>
            
            <div className="p-14 rounded-2xl max-w-3xl mx-auto" style={styles.cardBg}>
              <p className="text-xl text-white/60 leading-relaxed mb-20 font-extralight tracking-wide">
                Beyond my professional pursuits, I find fulfillment in a rich personal life deeply connected to family, nature, and continuous learning.
              </p>
              
              <ul className="space-y-10">
                {personalFacts.map((fact, index) => (
                  <li key={index} className="flex items-start gap-4">
                    <span style={{...styles.highlight, animation: 'gentlePulse 3s infinite ease-in-out'}} className="text-xl mt-1">✧</span>
                    <p className="text-lg text-white/50 font-extralight">{fact}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </section>

        {/* Contact Section */}
        <section 
          id="contact"
          ref={(el) => (sectionRefs.current.contact = el)}
          className="py-40 px-8 lg:px-0"
        >
          <div className="max-w-screen-lg mx-auto">
            <h2 className="text-4xl md:text-5xl font-thin mb-6 text-center tracking-wide">
              Let's <span style={styles.highlight} className="font-extralight">Connect</span>
            </h2>
            
            <p className="text-center text-white/60 font-extralight max-w-2xl mx-auto mb-40 text-lg tracking-wide">
              I believe in the power of human connection. Reach out to explore collaborations, share ideas, or simply start a conversation.
            </p>
            
            <div className="text-center p-20 rounded-2xl max-w-3xl mx-auto" style={styles.cardBg}>
              <p className="text-2xl text-white/80 mb-24 font-thin tracking-wide">
                birger.moell@gmail.com
              </p>
              <div className="flex flex-wrap justify-center gap-10 md:gap-20">
                <a 
                  href="https://www.linkedin.com/in/birgermoell"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="group transition-all duration-500 hover:scale-110"
                >
                  <span className="relative text-xl p-4">
                    <span className="absolute inset-0 rounded-lg blur-md" 
                      style={styles.linkGlow}
                      onMouseOver={(e) => {
                        e.currentTarget.style.background = styles.linkGlowHover.background;
                        e.currentTarget.style.boxShadow = styles.linkGlowHover.boxShadow;
                      }}
                      onMouseOut={(e) => {
                        e.currentTarget.style.background = styles.linkGlow.background;
                        e.currentTarget.style.boxShadow = styles.linkGlow.boxShadow;
                      }}></span>
                    <span className="relative text-white group-hover:text-amber-200 transition-colors duration-500 font-extralight tracking-widest text-base">LINKEDIN</span>
                  </span>
                </a>
                <a 
                  href="https://github.com/BirgerMoell"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="group transition-all duration-500 hover:scale-110"
                >
                  <span className="relative text-xl p-4">
                    <span className="absolute inset-0 rounded-lg blur-md" 
                      style={styles.linkGlow}
                      onMouseOver={(e) => {
                        e.currentTarget.style.background = styles.linkGlowHover.background;
                        e.currentTarget.style.boxShadow = styles.linkGlowHover.boxShadow;
                      }}
                      onMouseOut={(e) => {
                        e.currentTarget.style.background = styles.linkGlow.background;
                        e.currentTarget.style.boxShadow = styles.linkGlow.boxShadow;
                      }}></span>
                    <span className="relative text-white group-hover:text-amber-200 transition-colors duration-500 font-extralight tracking-widest text-base">GITHUB</span>
                  </span>
                </a>
                <a 
                  href="https://huggingface.co/birgermoell"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="group transition-all duration-500 hover:scale-110"
                >
                  <span className="relative text-xl p-4">
                    <span className="absolute inset-0 rounded-lg blur-md" 
                      style={styles.linkGlow}
                      onMouseOver={(e) => {
                        e.currentTarget.style.background = styles.linkGlowHover.background;
                        e.currentTarget.style.boxShadow = styles.linkGlowHover.boxShadow;
                      }}
                      onMouseOut={(e) => {
                        e.currentTarget.style.background = styles.linkGlow.background;
                        e.currentTarget.style.boxShadow = styles.linkGlow.boxShadow;
                      }}></span>
                    <span className="relative text-white group-hover:text-amber-200 transition-colors duration-500 font-extralight tracking-widest text-base">HUGGING FACE</span>
                  </span>
                </a>
                <a 
                  href="https://scholar.google.com/citations?user=4lPZnEAAAAAJ"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="group transition-all duration-500 hover:scale-110"
                >
                  <span className="relative text-xl p-4">
                    <span className="absolute inset-0 rounded-lg blur-md" 
                      style={styles.linkGlow}
                      onMouseOver={(e) => {
                        e.currentTarget.style.background = styles.linkGlowHover.background;
                        e.currentTarget.style.boxShadow = styles.linkGlowHover.boxShadow;
                      }}
                      onMouseOut={(e) => {
                        e.currentTarget.style.background = styles.linkGlow.background;
                        e.currentTarget.style.boxShadow = styles.linkGlow.boxShadow;
                      }}></span>
                    <span className="relative text-white group-hover:text-amber-200 transition-colors duration-500 font-extralight tracking-widest text-base">GOOGLE SCHOLAR</span>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </section>

        {/* Footer */}
        <footer className="py-20 text-center text-white/20 text-sm font-thin tracking-widest">
          <p>© {new Date().getFullYear()} BIRGER MOËLL</p>
        </footer>
      </main>
    </div>
  );
}

export default App;